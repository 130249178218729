<template lang="pug">
  .delete-btn
    v-dropdown(
      ref="dropdown"
      width-auto
      :disabled="disabled"
      :fixed-bottom="isMobile")
      v-btn(
        text
        danger
        :disabled="disabled") {{ 'actions.delete' | translate }}
      .delete-btn__content(
        ref="dropdownContent"
        slot="content")
        v-card(
          danger
          elevated)
          v-card-title
            .card-text.mb-3 {{ 'messages.info_delete' | translate }}
          v-card-actions
            v-btn.mr-2(
              danger
              :loading="loading"
              @click="$emit('delete')") {{ 'actions.delete' | translate }}
            v-btn(
              text
              @click="$refs.dropdown.closeDropdown()") {{ 'actions.cancel' | translate }}

</template>

<script>
import adaptationMixin from '@/mixins/adaptation.mixin'

export default {
  name: 'DeleteBtn',

  mixins: [adaptationMixin],

  props: {
    loading: Boolean,
    disabled: Boolean
  },

  methods: {
    close () {
      this.$refs.dropdown.closeDropdown()
    }
  }
}
</script>

<style lang="scss" scoped>
  .delete-btn {
    display: inline-flex;

    &__content {
      min-width: 32.5rem;
      width: 100%;
    }
  }
</style>
