<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.ingredient.settings.title' | translate }}
    v-card-content
      v-text-field.mb-3(
        :label="$t('base.code_1c')"
        v-model="form.code_1c")
      v-text-field.mb-3(
        :label="$t('base.valve_value')"
        v-model="form.valve_value")
      v-switch(
        :label="$t('base.autoload')"
        v-model="form.autoload")

    v-card-actions
      v-btn(
        :loading="loading"
        @click="onUpdate").mr-2 {{ 'actions.save' | translate }}
      delete-btn(
        v-permission-hide="permissions.delete_ingredient"
        @delete="onDelete")
</template>

<script>
import DeleteBtn from '@/components/common/delete-btn/DeleteBtn'
import permissions from '@/util/permissions'
import { mapActions } from 'vuex'

export default {
  name: 'SettingsModal',

  props: {
    item: Object
  },

  components: {
    DeleteBtn
  },

  data: () => ({
    form: {
      name: '',
      code_1c: '',
      autoload: false,
      valve_value: 3
    },
    loading: false,
    permissions: permissions
  }),

  mounted () {
    this.form.name = this.item.name
    this.form.code_1c = this.item.code_1c
    this.form.autoload = this.item.autoload
    this.form.valve_value = this.item.valve_value
  },

  methods: {
    ...mapActions(['updateIngredient']),
    async onUpdate () {
      this.loading = true
      const payload = {
        ...this.form
      }
      const { data, error } = await this.$store.dispatch('updateIngredient', { id: this.item.id, payload })
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    },

    async onDelete () {
      const { error } = await this.$store.dispatch('deleteIngredient', { id: this.item.id })
      if (!error) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
