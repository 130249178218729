import { windowResizeObserver } from '@/util/utils.js'

const MOBILE_BREAK_POINT = 800

export default {
  mounted () {
    windowResizeObserver.subscribe(this.initWidth)
  },

  destroyed () {
    windowResizeObserver.unsubscribe(this.initWidth)
  },

  data: () => ({
    windowWidth: window.innerWidth
  }),

  methods: {
    initWidth () {
      this.windowWidth = window.innerWidth
    }
  },

  computed: {
    isMobile () {
      return this.windowWidth <= MOBILE_BREAK_POINT
    }
  }
}
