export default {
  create_housing: 'create_housing',
  read_housing: 'read_housing',
  update_housing: 'update_housing',
  delete_housing: 'delete_housing',
  create_silage: 'create_silage',
  read_silage: 'read_silage',
  update_silage: 'update_silage',
  delete_silage: 'delete_silage',
  create_mixer: 'create_mixer',
  read_mixer: 'read_mixer',
  update_mixer: 'update_mixer',
  delete_mixer: 'delete_mixer',
  create_user: 'create_user',
  read_user: 'read_user',
  update_user: 'update_user',
  delete_user: 'delete_user',
  create_timetable: 'create_timetable',
  read_timetable: 'read_timetable',
  update_timetable: 'update_timetable',
  delete_timetable: 'delete_timetable',
  create_ration: 'create_ration',
  read_ration: 'read_ration',
  update_ration: 'update_ration',
  delete_ration: 'delete_ration',
  create_recipe: 'create_recipe',
  read_recipe: 'read_recipe',
  update_recipe: 'update_recipe',
  delete_recipe: 'delete_recipe',
  create_ingredient: 'create_ingredient',
  read_ingredient: 'read_ingredient',
  update_ingredient: 'update_ingredient',
  delete_ingredient: 'delete_ingredient',
  create_provider: 'create_provider',
  read_provider: 'read_provider',
  update_provider: 'update_provider',
  delete_provider: 'delete_provider',
  create_accounting: 'create_accounting',
  read_accounting: 'read_accounting',
  update_accounting: 'update_accounting',
  read_report: 'read_report',
  download_report: 'download_report'
}
